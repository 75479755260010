.register-container {
    font-family: "Red Hat Display", sans-serif;
    justify-content: center;
    align-items: center;
}

.register-background {
    background-image: url("https://i.ibb.co/zNDPxWM/gradient-Background.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
}

.register-tags {
    font-size: 36px;
}

.kind-of-container {
    padding-top: 14vh;
    justify-content: center;
    display: flex;
}

.register-main-container-loading {
    padding-top: 20vh;
}

@media screen and (max-width: 768px) {
    .register-container {
        overflow-x: hidden;
    }
    .register-tags {
        font-size: 24px;
    }
    .kind-of-container {
        padding: 12vh;
        overflow-x: hidden;
    }
    .register-main-container-loading {
        padding-top: 10vh;
        justify-content: center;
        align-items: center;
    }
}