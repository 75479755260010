/* Home.css */

.home-container {
    font-family: "Red Hat Display", sans-serif;
}

.home-background {
    background-image: url("https://i.ibb.co/zNDPxWM/gradient-Background.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
}

.content-wrapper {
    padding-top: 150px;
    text-align: center;
}

.fade-in {
    opacity: 0;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in:nth-child(1) {
    animation: fadeIn 0.7s ease-in 0.2s forwards;
}

.fade-in:nth-child(2) {
    animation: fadeIn 0.7s ease-in 0.4s forwards;
}

.fade-in:nth-child(3) {
    animation: fadeIn 0.7s ease-in 0.6s forwards;
}

.heading {
    display: block;
    max-width: 580px;
    margin: auto;
    font-size: 40px;
    font-weight: bold;
}

.start-selling-btn {
    background: #ffc107;
    color: rgb(0, 0, 0);
    border-color: #ffc107;
}

.image-container {
    text-align: right;
}

.image-container {
    width: 100%;
    height: 100%;
}

@media (max-width: 760px) {
    .content-wrapper {
        padding-top: 100px;
    }
    .image-container img {
        width: 0%;
        height: 0%;
    }
    .image-container {
        display: none;
    }
    .heading {
        font-size: 20px;
        text-align: center;
        line-height: 1.5;
    }
    .vector-image {
        display: none;
    }
    .illustrate {
        font-size: 10px;
    }
    .home-background {
        height: 80vh;
    }
}