.ShowCaseBoardContainer {
    background-size: cover;
    align-items: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    font-family: "Times New Roman", Times, serif;
}

.ShowCaseBoardContentContainer {
    display: block;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 20px;
    box-sizing: border-box;
}

.ShowCaseBoardHeading {
    font-size: 40px;
    color: white;
    font-weight: bold;
    display: block;
    margin-bottom: 20px;
    text-align: center;
}

.ShowCaseBoardContent {
    font-size: 16px;
    color: white;
    display: block;
    text-align: justify;
    margin-bottom: 20px;
}

.ShowCaseBoardLink {
    font-size: 16px;
    color: white;
    display: block;
    text-align: center;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .ShowCaseBoardHeading {
        font-size: 30px;
    }
    .ShowCaseBoardContent {
        font-size: 14px;
    }
    .ShowCaseBoardLink {
        font-size: 14px;
    }
}