.contact-input-container {
    width: 50%;
}

.support-banner {
    width: 100%;
    height: 60ch;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .contact-input-container {
        width: 100%;
    }
}