.pricing-board-container {
    padding: 11px;
    border: 1px solid rgb(0, 0, 0, 0.1);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgb(0, 0, 0, 0.1);
}

.pricing-board-container:hover {
    elevation: 10;
    transition: 0.5s;
    transform: scale(1.02);
}

.pricing-board-heading {
    font-size: 22px;
    font-weight: bold;
    color: #000;
    text-align: center;
}

.fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
}