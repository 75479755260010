.new-store-container {
    font-family: "Red Hat Display", sans-serif;
}

.new-store-background {
    background-image: url("https://i.ibb.co/zNDPxWM/gradient-Background.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
}

@media screen and (max-width: 768px) {
    .vector-image-newStore {
        display: none;
        width: 0px;
        height: 0px;
    }
}