.pricing-heading {
    font-size: 30px;
    font-weight: bold;
}

.pricing-heading-container {
    display: block;
    width: 40%;
    text-align: center;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
    .pricing-heading {
        font-size: 24px;
    }
    .pricing-heading-container {
        display: block;
        width: 70%;
        text-align: center;
        margin: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}